<template>
    <div class="browser-page">
        <p class="text">当前环境不支持开启摄像头</p>
        <p class="text">请复制下面链接前往浏览器打开</p>
        <div class="input-group">
            <input type="text" name="" id="copy-input" readonly :value="inputVal" />
            <span class="input-group-button">
                <button class="btn copy-elm" ref="btnRef" data-clipboard-target="#copy-input"
                    >复制</button
                >
            </span>
        </div>
        <p class="tag">操作完成请点击【我已完成认证】</p>
        <Button
            class="btn-w"
            type="primary"
            @click="submitDown"
            :disabled="loading"
            :loading="loading"
            loading-text="结果检测中,请等待"
            >我已完成认证</Button
        >
    </div>
</template>
<script lang="ts" setup>
    import { onMounted, ref } from 'vue'
    import { useRoute } from 'vue-router'
    import { Dialog, Button, Toast } from 'vant'
    import createPostMsg from '@/hooks/createPostMsg'
    import ClipboardJS from 'clipboard'
    import { v4 as uuidv4 } from 'uuid'
    import { setObjToUrlParams } from '@/utils/url'
    import { cloneDeep } from 'lodash-es'
    import { friesVerifierInfo } from '@/api/publicApi'

    const route = useRoute()

    const inputVal = ref('')
    const loading = ref(false)

    const requestId = ref('')

    const { setPostMsg } = createPostMsg(route.query)

    let query

    onMounted(() => {
        getSearch()
        clipboardInit()
    })

    const getSearch = () => {
        requestId.value = uuidv4().replace(/-/g, '')
        query = cloneDeep(route.query)
        query.arouseHandle = '2'
        query.isBrowser = '1'
        query.requestId = requestId.value
        inputVal.value = setObjToUrlParams(window.location.origin, query)
    }
    const clipboardInit = () => {
        const clipboard = new ClipboardJS('.copy-elm')
        clipboard.on('success', function (e) {
            Dialog.alert({
                title: '复制成功',
                message: '使用复制的链接在浏览器打开',
                theme: 'round-button',
            })
            e.clearSelection()
        })
        clipboard.on('error', function () {
            Dialog.confirm({
                title: '复制失败',
                message: `请手动复制下面链接\n\n${inputVal.value}`,
                showCancelButton: false,
                confirmButtonText: '关闭',
            }).then(() => {})
        })
    }
    const submitDown = () => {
        loading.value = true
        friesVerifierInfo({ requestId: requestId.value })
            .then(res => {
                loading.value = false
                if (res?.code == 200 && res.data) {
                    const { apiRequestId, clientType, recogResult, image, faceResultInfos } =
                        res.data

                    const postData: Partial<PostMessageType> = {
                        code: undefined,
                        requestCode: undefined,
                        requestMsg: undefined,
                        faceComparisonPicture:
                            query.faceModule == 1
                                ? image
                                : faceResultInfos?.find(item => item.itemId == 2)?.images[0],
                        faceVerifyPicture: faceResultInfos?.find(item => item.itemId == 3)?.images,
                        faceLivenessVideo: faceResultInfos?.find(item => item.itemId == 4)
                            ?.images[0],
                        clientType: clientType,
                        apiRequestId: apiRequestId,
                    }
                    if (recogResult == 'SUCCESS') {
                        postData.code = 200
                        postData.requestCode = query.faceModule == 1 ? 201 : 200
                        postData.requestMsg = 'SUCCESS'
                    } else {
                        postData.code = 200
                        postData.requestCode = 300
                        postData.requestMsg = 'FAIL'
                        Toast.fail('识别失败')
                    }
                    setPostMsg(postData)
                } else {
                    Toast.fail('未查询到识别记录')
                }
            })
            .catch(() => {
                loading.value = false
            })
    }
</script>
<style scoped lang="scss">
    .browser-page {
        padding-top: 80px;
        width: 100%;
        text-align: center;
        .text {
            font-size: 15px;
            font-weight: 600;
            + .text {
                margin-top: 25px;
            }
        }
        .input-group {
            display: flex;
            align-items: center;
            margin: 0 auto;
            margin-top: 70px;
            width: 80%;
            height: 40px;
            input {
                padding: 7px 8px;
                flex-grow: 1;
                height: 100%;
                font-size: 13px;
                border: 1px solid #cccccc;
                border-right: none;
                border-radius: 3px;
                color: #333333;
                background-color: #ffffff;
                outline: none;
                box-shadow: inset 0 1px 2px rgb(0 0 0 / 8%);
                box-sizing: border-box;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
            .input-group-button {
                height: 100%;
                .btn {
                    width: 60px;
                    height: 100%;
                    border: 1px solid #d5d5d5;
                    border-radius: 3px;
                    color: #333333;
                    background-color: #eeeeee;
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                }
            }
        }
        .tag {
            margin-top: 50px;
            font-size: 14px;
            color: #555555;
        }
        .btn-w {
            margin-top: 20px;
            width: 180px;
        }
    }
</style>
