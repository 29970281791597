import axios, { AxiosResponse } from 'axios'
import router from '@/router'
import { Dialog, Toast } from 'vant'
import baseURL from '@/config/baseURL'
import { ContentTypeEnum } from '@/enums/httpEnum'
import { checkStatus } from './checkStatus'
// import createPostMsg from '@/hooks/createPostMsg'

import type { AxiosInstance, AxiosRequestConfig } from 'axios'
import type { RequestConfig, RequestInterceptors, Result } from './types'

class Request {
    // axios 实例
    Axios: AxiosInstance
    request: <T = Result>(config: RequestConfig) => Promise<T>
    // axios配置
    config: RequestConfig = {
        baseURL,
        timeout: 0,
        headers: { 'Content-Type': ContentTypeEnum.JSON },
        responseType: 'json',
        withCredentials: false,
    }
    // 拦截器对象
    interceptors?: RequestInterceptors
    static Axios: any

    constructor(config: RequestConfig) {
        this.config = Object.assign({}, this.config, config)

        this.Axios = axios.create(this.config)

        this.interceptorsInit()

        this.interceptors = config.interceptors

        this.request = <T = Result>(config: RequestConfig): Promise<T> => {
            return new Promise((resolve, reject) => {
                this.Axios.request<any, AxiosResponse<Result>>(config)
                    .then((res: AxiosResponse<Result>) => {
                        return resolve(res as unknown as Promise<T>)
                    })
                    .catch((err: any) => {
                        reject(err)
                    })
            })
        }
    }

    // 拦截器初始化
    interceptorsInit() {
        // 请求拦截器
        this.Axios.interceptors.request.use(
            this.interceptors?.requestInterceptors ||
                ((config: AxiosRequestConfig) => {
                    if (config.headers) {
                        const token =
                            router.currentRoute.value.query['x-zahb-paas-auth-token'] ||
                            config.headers['x-zahb-paas-auth-token'] ||
                            ''
                        config.headers['x-zahb-paas-auth-token'] = token as string
                    }
                    return config
                }),
            this.interceptors?.requestInterceptorsCatch ||
                (error => {
                    return Promise.reject(error)
                })
        )

        // 响应拦截器
        this.Axios.interceptors.response.use(
            this.interceptors?.responseInterceptors ||
                (response => {
                    const data = response.data
                    if (data?.code == 401) {
                        // const { setErrorPostMsg } = createPostMsg(router.currentRoute.value.query)
                        // setErrorPostMsg({
                        //     code: 401,
                        //     msg: 'x-zahb-paas-auth-token 过期',
                        // })
                    }
                    return data
                }),
            this.interceptors?.responseInterceptorsCatch ||
                (error => {
                    const { code, message, response } = error.toJSON() || {}
                    try {
                        if (code === 'ECONNABORTED' && message.indexOf('timeout') !== -1) {
                            Dialog.alert({
                                title: '请求超时',
                                message: '接口请求超时,请检查您的网络连接是否正常!',
                                confirmButtonText: '刷新重试',
                                showCancelButton: false,
                            }).then(() => {
                                window.location.reload()
                            })
                            return
                        }
                        if (message && message.includes('Network Error')) {
                            Dialog.alert({
                                title: '网络异常',
                                message: '请检查您的网络连接是否正常!',
                                confirmButtonText: '刷新重试',
                                showCancelButton: false,
                            }).then(() => {
                                window.location.reload()
                            })
                            return
                        }
                    } catch (error: any) {
                        throw new Error(error)
                    }

                    checkStatus(error?.response?.status, response?.data?.message || '', Toast)
                    // const { setErrorPostMsg } = createPostMsg(router.currentRoute.value.query)
                    // setErrorPostMsg({
                    //     code: 500,
                    //     msg: '服务器异常',
                    // })
                    return Promise.reject(error)
                })
        )
    }
}

const http = new Request({})
export { Result }
export default http.request
