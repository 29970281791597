import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "browser-page" }
const _hoisted_2 = { class: "input-group" }
const _hoisted_3 = ["value"]
const _hoisted_4 = { class: "input-group-button" }
const _hoisted_5 = {
  class: "btn copy-elm",
  ref: "btnRef",
  "data-clipboard-target": "#copy-input"
}

import { onMounted, ref } from 'vue'
    import { useRoute } from 'vue-router'
    import { Dialog, Button, Toast } from 'vant'
    import createPostMsg from '@/hooks/createPostMsg'
    import ClipboardJS from 'clipboard'
    import { v4 as uuidv4 } from 'uuid'
    import { setObjToUrlParams } from '@/utils/url'
    import { cloneDeep } from 'lodash-es'
    import { friesVerifierInfo } from '@/api/publicApi'

    
export default /*@__PURE__*/_defineComponent({
  __name: 'BrowserPage',
  setup(__props) {

    const route = useRoute()

    const inputVal = ref('')
    const loading = ref(false)

    const requestId = ref('')

    const { setPostMsg } = createPostMsg(route.query)

    let query

    onMounted(() => {
        getSearch()
        clipboardInit()
    })

    const getSearch = () => {
        requestId.value = uuidv4().replace(/-/g, '')
        query = cloneDeep(route.query)
        query.arouseHandle = '2'
        query.isBrowser = '1'
        query.requestId = requestId.value
        inputVal.value = setObjToUrlParams(window.location.origin, query)
    }
    const clipboardInit = () => {
        const clipboard = new ClipboardJS('.copy-elm')
        clipboard.on('success', function (e) {
            Dialog.alert({
                title: '复制成功',
                message: '使用复制的链接在浏览器打开',
                theme: 'round-button',
            })
            e.clearSelection()
        })
        clipboard.on('error', function () {
            Dialog.confirm({
                title: '复制失败',
                message: `请手动复制下面链接\n\n${inputVal.value}`,
                showCancelButton: false,
                confirmButtonText: '关闭',
            }).then(() => {})
        })
    }
    const submitDown = () => {
        loading.value = true
        friesVerifierInfo({ requestId: requestId.value })
            .then(res => {
                loading.value = false
                if (res?.code == 200 && res.data) {
                    const { apiRequestId, clientType, recogResult, image, faceResultInfos } =
                        res.data

                    const postData: Partial<PostMessageType> = {
                        code: undefined,
                        requestCode: undefined,
                        requestMsg: undefined,
                        faceComparisonPicture:
                            query.faceModule == 1
                                ? image
                                : faceResultInfos?.find(item => item.itemId == 2)?.images[0],
                        faceVerifyPicture: faceResultInfos?.find(item => item.itemId == 3)?.images,
                        faceLivenessVideo: faceResultInfos?.find(item => item.itemId == 4)
                            ?.images[0],
                        clientType: clientType,
                        apiRequestId: apiRequestId,
                    }
                    if (recogResult == 'SUCCESS') {
                        postData.code = 200
                        postData.requestCode = query.faceModule == 1 ? 201 : 200
                        postData.requestMsg = 'SUCCESS'
                    } else {
                        postData.code = 200
                        postData.requestCode = 300
                        postData.requestMsg = 'FAIL'
                        Toast.fail('识别失败')
                    }
                    setPostMsg(postData)
                } else {
                    Toast.fail('未查询到识别记录')
                }
            })
            .catch(() => {
                loading.value = false
            })
    }

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[1] || (_cache[1] = _createElementVNode("p", { class: "text" }, "当前环境不支持开启摄像头", -1)),
    _cache[2] || (_cache[2] = _createElementVNode("p", { class: "text" }, "请复制下面链接前往浏览器打开", -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("input", {
        type: "text",
        name: "",
        id: "copy-input",
        readonly: "",
        value: inputVal.value
      }, null, 8, _hoisted_3),
      _createElementVNode("span", _hoisted_4, [
        _createElementVNode("button", _hoisted_5, "复制", 512)
      ])
    ]),
    _cache[3] || (_cache[3] = _createElementVNode("p", { class: "tag" }, "操作完成请点击【我已完成认证】", -1)),
    _createVNode(_unref(Button), {
      class: "btn-w",
      type: "primary",
      onClick: submitDown,
      disabled: loading.value,
      loading: loading.value,
      "loading-text": "结果检测中,请等待"
    }, {
      default: _withCtx(() => _cache[0] || (_cache[0] = [
        _createTextVNode("我已完成认证")
      ])),
      _: 1
    }, 8, ["disabled", "loading"])
  ]))
}
}

})