<template>
    <svg :class="svgClass" aria-hidden="true">
        <use :href="iconName" />
    </svg>
</template>

<script lang="ts" setup>
    import { computed } from 'vue'

    const props = defineProps({
        iconClass: {
            type: String,
            default: '',
        },
        className: {
            type: String,
            default: '',
        },
    })
    const iconName = computed(() => `#icon-${props.iconClass}`)
    const svgClass = computed(() => {
        if (props.className) {
            return 'svg-icon ' + props.className
        } else {
            return 'svg-icon'
        }
    })
</script>

<style scoped lang="scss">
    .svg-icon {
        overflow: hidden;
        width: 1em;
        height: 1em;
        fill: currentcolor;
    }
</style>
