import http from '@/request'
import { ContentTypeEnum } from '@/enums/httpEnum'

export interface baseParamsType {
    userId: string
    // userName: string
    rootOrgId: string
    requestId?: string
    extensions?: string
}

// 获取 x-zahb-paas-auth-token
export const getAuthToken = () => {
    return http({
        url: '/auth/api/access_token',
        method: 'get',
        headers: {
            'Content-Type': ContentTypeEnum.JSON,
        },
    })
}
// oss 鉴权
export const getOssSecret = () => {
    return http({
        url: '/fries/oss/temporary_token',
        method: 'get',
        headers: {
            'Content-Type': ContentTypeEnum.JSON,
        },
    })
}

// 上传文件
export const friesUpload = data => {
    //http://192.168.0.131:8000
    return http({
        url: '/fries/upload',
        method: 'post',
        headers: {
            'Content-Type': ContentTypeEnum.FORM_DATA,
        },
        data,
    })
}

// 获取Baidu视频活体检测sessionCode
export const getBaiduSessionCode = () => {
    return http({
        url: '/fries/baidu_action',
        method: 'get',
        headers: {
            'Content-Type': ContentTypeEnum.JSON,
        },
    })
}

export interface friesDetectParamsType extends baseParamsType {
    image: string
    imageType: string //'BASE64' | 'URL'
}

// 人脸检测
export const friesDetect = (data: friesDetectParamsType) => {
    return http({
        url: '/fries/detect',
        method: 'POST',
        headers: {
            'Content-Type': ContentTypeEnum.JSON,
        },
        data,
    })
}

export interface friesVerifierParamsType extends baseParamsType {
    referencePicture: string //参考图片
    faceData: Array<{
        score?: number //活体分数值
        images: string[]
        imageType: 'BASE64' | 'URL'
        baiduSessionId?: string //百度视频会话id
        itemId: 1 | 2 | 3 | 4 | 5
    }>
}

// 人脸核验
export const friesVerifier = (data: friesVerifierParamsType) => {
    return http({
        url: '/fries/verifier',
        method: 'POST',
        timeout: 0,
        headers: {
            'Content-Type': ContentTypeEnum.JSON,
        },
        data,
    })
}

//获取核验信息
export const friesVerifierInfo = (params: { requestId: string }) => {
    return http({
        url: '/fries/callback_result',
        method: 'GET',
        timeout: 0,
        headers: {
            'Content-Type': ContentTypeEnum.JSON,
        },
        params,
    })
}
