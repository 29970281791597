<template>
    <div class="container">
        {{ MSG }}
    </div>
</template>
<script lang="ts" setup>
    import { onMounted } from 'vue'
    import { useRouter } from 'vue-router'
    import validateSearch from '@/hooks/validateSearch'

    const router = useRouter()

    const { isActive, MSG, query } = validateSearch()

    onMounted(() => {
        if (isActive.value) {
            changeRouterPath()
        }
    })

    const changeRouterPath = () => {
        let path
        if (query.faceModule == 1) {
            //人脸检测
            path = '/faceDetection'
        }
        if (query.faceModule == 2) {
            //人脸比对
            path = '/faceComparison'
        }
        if (query.faceModule == 3) {
            //图片活体检测
            path = '/faceVerify'
        }
        if (query.faceModule == 4) {
            //视频活体检测
            path = '/faceLiveness'
        }
        if (query.faceModule == 5) {
            //常驻摄像头
            path = '/faceResidentCamera'
        }
        if (query.faceModule == 6) {
            //身份证 + 人脸对比
            path = '/faceComparison'
        }
        if (query.faceModule == 7) {
            //身份证 + 图片活体检测
            path = '/faceVerify'
        }
        if (query.faceModule == 8) {
            //身份证 + 视频活体检测
            path = '/faceLiveness'
        }
        router.replace({
            path,
            query,
        })
    }
</script>
<style scoped lang="scss">
    .container {
        padding-top: 30px;
        width: 100%;
        font-size: 16px;
        text-align: center;
        color: red;
    }
</style>
