import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["href"]

import { computed } from 'vue'

    
export default /*@__PURE__*/_defineComponent({
  __name: 'SvgIcon',
  props: {
        iconClass: {
            type: String,
            default: '',
        },
        className: {
            type: String,
            default: '',
        },
    },
  setup(__props) {

    const props = __props
    const iconName = computed(() => `#icon-${props.iconClass}`)
    const svgClass = computed(() => {
        if (props.className) {
            return 'svg-icon ' + props.className
        } else {
            return 'svg-icon'
        }
    })

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("svg", {
    class: _normalizeClass(svgClass.value),
    "aria-hidden": "true"
  }, [
    _createElementVNode("use", { href: iconName.value }, null, 8, _hoisted_1)
  ], 2))
}
}

})