import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container" }

import { onMounted } from 'vue'
    import { useRouter } from 'vue-router'
    import validateSearch from '@/hooks/validateSearch'

    
export default /*@__PURE__*/_defineComponent({
  __name: 'AccessView',
  setup(__props) {

    const router = useRouter()

    const { isActive, MSG, query } = validateSearch()

    onMounted(() => {
        if (isActive.value) {
            changeRouterPath()
        }
    })

    const changeRouterPath = () => {
        let path
        if (query.faceModule == 1) {
            //人脸检测
            path = '/faceDetection'
        }
        if (query.faceModule == 2) {
            //人脸比对
            path = '/faceComparison'
        }
        if (query.faceModule == 3) {
            //图片活体检测
            path = '/faceVerify'
        }
        if (query.faceModule == 4) {
            //视频活体检测
            path = '/faceLiveness'
        }
        if (query.faceModule == 5) {
            //常驻摄像头
            path = '/faceResidentCamera'
        }
        if (query.faceModule == 6) {
            //身份证 + 人脸对比
            path = '/faceComparison'
        }
        if (query.faceModule == 7) {
            //身份证 + 图片活体检测
            path = '/faceVerify'
        }
        if (query.faceModule == 8) {
            //身份证 + 视频活体检测
            path = '/faceLiveness'
        }
        router.replace({
            path,
            query,
        })
    }

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_unref(MSG)), 1))
}
}

})