import { reactive, toRaw } from 'vue'
import { isObject } from '@/utils/is'
/**
 *
 * @param query
 * @returns
 */
export default function (query) {
    type dataType = Partial<PostMessageType>

    const version = '1.0' //版本号

    const postMsg: dataType = reactive({
        type: 'FACE_LIVENESS',
        code: undefined,
        requestMsg: undefined,
        faceModule: query.faceModule,
        requestCode: undefined,
        referencePicture: query.referencePicture,
        faceComparisonPicture: undefined,
        faceVerifyPicture: undefined,
        faceLivenessVideo: undefined,
        errMsg: undefined,
        apiRequestId: undefined,
        userId: query.userId,
        rootOrgId: query.rootOrgId,
        extensions: query.extensions,
        clientType: undefined,
        version: version,
    })

    const resetPostMsg = () => {
        postMsg.code = undefined
        postMsg.requestMsg = undefined
        postMsg.requestCode = undefined
        postMsg.faceComparisonPicture = undefined
        postMsg.faceVerifyPicture = undefined
        postMsg.faceLivenessVideo = undefined
        postMsg.errMsg = undefined
        postMsg.apiRequestId = undefined
        postMsg.clientType = undefined
    }

    const setPostMsg = (data: dataType, notice = true) => {
        resetPostMsg()

        if (data && isObject(data)) {
            Object.keys(data).forEach(item => {
                postMsg[item] = data[item]
            })
        }
        if (notice) {
            noticeMassage()
        }
    }

    const noticeMassage = () => {
        console.log(toRaw(postMsg))

        if (query.isBrowser != '1' && window.self !== window.top) {
            window.parent.postMessage(toRaw(postMsg), query.targetOrigin as string)
        }
    }

    return {
        postMsg,
        setPostMsg,
        noticeMassage,
    }
}
