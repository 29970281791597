export function checkStatus(status: number, msg: string, message: any): void {
    switch (status) {
        case 400:
            message.fail(`${msg}`)
            break
        case 401:
            message.fail('用户没有权限（令牌、用户名、密码错误）!')
            break
        case 403:
            message.fail('用户得到授权，但是访问是被禁止的。!')
            break
        // 404请求不存在
        case 404:
            message.fail('网络请求错误,未找到该资源!')
            break
        case 405:
            message.fail('网络请求错误,请求方法未允许!')
            break
        case 408:
            message.fail('网络请求超时!')
            break
        case 500:
            message.fail('服务器错误,请联系管理员!')
            break
        case 501:
            message.fail('网络未实现!')
            break
        case 502:
            message.fail('网络错误!')
            break
        case 503:
            message.fail('服务不可用，服务器暂时过载或维护!')
            break
        case 504:
            message.fail('网络超时!')
            break
        case 505:
            message.fail('http版本不支持该请求!')
            break
        default:
            message.fail(msg)
    }
}
